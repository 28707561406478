import styled, { device } from "@styled";

export const LanguageGuidesWrapper = styled.section`
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0;
  background: linear-gradient(to bottom, #f8f8f8 50%, #f0f0f0 0%);
  ${device.large} {
    background: linear-gradient(to right, #f8f8f8 50%, #f0f0f0 0%);
  }
  .section-title {
    p {
      color: #fff;
    }
  }
  a {
    border-radius: 4px;
    background: #fff;
    border-color: #e2e2e2;
    font-size: 17px;
  }
`;

export const LanguageGuidesInner = styled.div``;

export const LanguageGuidesBtns = styled.div`
  margin-left: -7px;
  margin-right: -7px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 3px;
  margin-top: 60px;
  margin-bottom: 60px;
  ${device.large} {
    margin-bottom: 0;
  }
  ${device.xlarge} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
