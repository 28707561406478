import styled, { css, device, themeGet } from "@styled";
import asianmap from "@data/images/bg/asianmap-bg.png";

export const LanguageGuidesWrapper = styled.section`
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0;

  .section-title {
    p {
      color: #fff;
    }
  }
  a {
    border-radius: 4px;
    background: #fff;
    border-color: #e2e2e2;
    font-size: 17px;
  }
  ${(props) =>
    props.layout === 1 &&
    css`
      background: url(${asianmap});
      background-repeat: no-repeat;
      background-position: center right;
      background-size: contain;
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      background: #f8f8f8 url(${asianmap});
      background-repeat: no-repeat;
      background-position: center right;
      background-size: contain;
    `}

${(props) =>
    props.layout === 3 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.secondaryDark")},
        ${themeGet("colors.secondary")}
      );
      .section-title {
        h6 {
          color: #fff;
        }
        p {
          color: #fff;
        }
      }
      a {
        border-radius: 4px;
        background: rgb(255 255 255 / 11%);
        border-color: rgb(255 255 255 / 41%);
        color: #fff;
        &:hover {
          color: #fff;
        }
      }
    `}
${(props) =>
    props.layout === 4 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.primaryDark")},
        ${themeGet("colors.primary")}
      );
      .section-title {
        h6 {
          color: #fff;
        }
        h2 {
          border-color: #fff;
        }
        p {
          color: #fff;
        }
      }
      a {
        border-radius: 4px;
        background: rgb(255 255 255 / 11%);
        border-color: rgb(255 255 255 / 41%);
        color: #fff;
        &:hover {
          color: #fff;
        }
      }
    `}
    ${(props) =>
    props.layout === 5 &&
    css`
      background: #f8f8f8;
    `}
      ${(props) =>
    props.layout === 6 &&
    css`
      background: #f0f0f0;
    `}
`;

export const LanguageGuidesInner = styled.div``;

export const LanguageGuidesBtns = styled.div`
  margin-left: -7px;
  margin-right: -7px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 3px;
  margin-top: 60px;
  ${device.small} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.medium} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${device.large} {
    grid-template-columns: repeat(5, 1fr);
  }
`;
