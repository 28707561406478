import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Button from "@ui/button";
import {
  LanguageGuidesWrapper,
  LanguageGuidesInner,
  LanguageGuidesBtns,
} from "./style";

const LanguageGuides = ({ middleeastdata, americasdata }) => {
  return (
    <LanguageGuidesWrapper>
      <LanguageGuidesInner>
        <Container>
          <Row>
            <Col lg={6}>
              <SectionTitle
                subtitle={middleeastdata.section_title?.subtitle}
                title={middleeastdata.section_title?.title}
                description={middleeastdata.section_title?.description}
              />
              <LanguageGuidesBtns>
                {middleeastdata.buttons &&
                middleeastdata?.buttons?.map(({ id, content, ...rest }) => (
                  <Button key={id} m="7px" {...rest}>
                    {content}
                  </Button>
                ))}
              </LanguageGuidesBtns>
            </Col>
            <Col lg={6}>
              <SectionTitle
                subtitle={americasdata.section_title?.subtitle}
                title={americasdata.section_title?.title}
                description={americasdata.section_title?.description}
              />
              <LanguageGuidesBtns>
                {americasdata.buttons &&
                americasdata?.buttons?.map(({ id, content, ...rest }) => (
                  <Button key={id} m="7px" {...rest}>
                    {content}
                  </Button>
                ))}
              </LanguageGuidesBtns>
            </Col>
          </Row>
        </Container>
      </LanguageGuidesInner>
    </LanguageGuidesWrapper>
  );
};
export default LanguageGuides;
